import React from 'react'

import cv from '../../assets/cv.pdf'
import EmailIcon from '../../UI/Icons/EmailIcon/EmailIcon'
import GhIcon from '../../UI/Icons/GhIcon/GhIcon'
import LinkedIn from '../../UI/Icons/LinkedIn/LinkedIn'

import styles from './Menu.module.scss'

const Menu = ({
  toggle,
  canTriggerAnimation,
  triggerAnimation,
  switchToggleState
}) => {
  const scrollToSection = (id) => {
    if (canTriggerAnimation) {
      switchToggleState()
      triggerAnimation()
      setTimeout(() => {
        scroll(id, 1000)
      }, 1500)
    } else {
      switchToggleState()
      scroll(id, 1000)
    }
  }

  const scroll = (id, duration) => {
    const target = document.getElementById(id)
    const targetPosition = target.getBoundingClientRect().top
    const startPosition = document.getElementById('secondPage').scrollTop
    let distance = targetPosition

    let startTime = null

    let animation = (currentTime) => {
      if (startTime === null) startTime = currentTime
      let timeElapsed = currentTime - startTime
      let run = ease(timeElapsed, startPosition, distance, duration)
      document.getElementById('secondPage').scrollTo(0, run)
      if (timeElapsed < duration) requestAnimationFrame(animation)
    }

    const ease = (t, b, c, d) => {
      t /= d / 2
      if (t < 1) return (c / 2) * t * t * t + b
      t -= 2
      return (c / 2) * (t * t * t + 2) + b
    }

    requestAnimationFrame(animation)
  }

  return (
    <div>
      <nav
        role="navigation"
        className={[styles.menu, toggle ? styles.show : null].join(' ')}
      >
        <button onClick={() => scrollToSection('about-me')}>
          <li>About</li>
        </button>
        <button onClick={() => scrollToSection('myProjects')}>
          <li>Projects</li>
        </button>
        <a href={cv} download="Andrei Karas CV">
          <li>Resume</li>
        </a>
        <button onClick={() => scrollToSection('contactMe')}>
          <li>Contact</li>
        </button>
        <hr />
        <div className={styles.iconSection}>
          <LinkedIn />
          <EmailIcon />
          <GhIcon />
        </div>
      </nav>
    </div>
  )
}

export default Menu
