import React, { useState } from 'react'

import { ReactComponent as GithubIcon } from '../../assets/github.svg'
import styles from './Project.module.scss'

const Project = ({
  name,
  color,
  hoverColor,
  image,
  imageWebp,
  githubLink,
  link,
  right,
  text
}) => {
  const [hover, setHover] = useState(false)
  const [hoverGH, setHoverGH] = useState(false)
  return (
    <div
      className={right ? styles.projectContainerRight : styles.projectContainer}
    >
      <div className={styles.imageContainer} style={{ backgroundColor: color }}>
        <picture>
          <source type="image/webp" srcSet={imageWebp} />
          <source type="image/png" srcSet={image} />
          <img src={image} alt="Project of mine" />
        </picture>
      </div>
      <div className={styles.projectTech}>Web-App</div>
      <div className={styles.projectInfo}>
        <h3 className={styles.projectName}>
          {name}
          <div className={styles.projectGithub}>
            <a href={githubLink}>
              <GithubIcon
                onMouseEnter={() => {
                  setHoverGH(true)
                }}
                onMouseLeave={() => {
                  setHoverGH(false)
                }}
                className={styles.githubIcon}
                style={
                  hoverGH
                    ? {
                        fill: color
                      }
                    : {
                        fill: hoverColor
                      }
                }
              />
            </a>
          </div>
        </h3>
        <h4 className={styles.projectText}>{text}</h4>

        <a
          className={styles.projectLink}
          onMouseEnter={() => {
            setHover(true)
          }}
          onMouseLeave={() => {
            setHover(false)
          }}
          style={
            hover
              ? {
                  color: color
                }
              : {
                  color: hoverColor
                }
          }
          href={`https://${link}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {link}
        </a>
      </div>
    </div>
  )
}

export default React.memo(Project)
