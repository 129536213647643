import React from 'react'

import Arrow from '../../UI/Arrow/Arrow'
import styles from './Hero.module.scss'

const HeroSection = () => {
  return (
    <div className={styles.hero} id="Hero">
      <h1 className={styles.header}>Not Your Average Web Developer</h1>
      <Arrow className={styles.arrow} />
    </div>
  )
}

export default React.memo(HeroSection)
