import React from 'react'

import { ReactComponent as LinkedInIcon } from '../../../assets/linkedin.svg'
import styles from './LinkedIn.module.scss'

const LinkedIn = () => {
  return (
    <a href="https://www.linkedin.com/in/andrei-karas/" className={styles.linkedInIconContainer}>
      <LinkedInIcon className={styles.linkedInIcon} />
    </a>
  )
}

export default LinkedIn
