import React from 'react'

import cv from '../../assets/cv.pdf'
import EmailIcon from '../../UI/Icons/EmailIcon/EmailIcon'
import GhIcon from '../../UI/Icons/GhIcon/GhIcon'
import LinkedInIcon from '../../UI/Icons/LinkedIn/LinkedIn'
import ResumeIcon from '../../UI/Icons/ResumeIcon/ResumeIcon'
import styles from './Contact.module.scss'

const Contact = () => {
  return (
    <div className={styles.contactContainer} id="contactMe">
      <div className={styles.contactWrapper}>
        <div className={styles.contact}>Contact</div>
        <div className={styles.contactText}>
          Let's work together! You can contact me and find out more by using
          info below:
        </div>
        <div className={styles.contactLinks}>
          <div className={styles.contactLinkText}>
            <EmailIcon />
            <a href="mailto:andrei@andreikaras.com">andrei@andreikaras.com</a>
          </div>
          <div className={styles.contactLinkText}>
            <GhIcon />
            <a href="https://github.com/Justbigmack" className={styles.test}>
              GitHub
            </a>
          </div>
          <div className={styles.contactLinkText}>
            <LinkedInIcon />
            <a href="https://www.linkedin.com/in/andrei-karas/">LinkedIn</a>
          </div>
          <div className={styles.contactLinkText}>
            <ResumeIcon />
            <a href={cv} download="Andrei Karas CV">
              CV
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(Contact)
