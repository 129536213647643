import React from 'react'

import Logo from '../../UI/Logo/Logo'

import styles from './Navbar.module.scss'

const Navbar = () => {
  return (
    <div className={styles.navbar}>
      <Logo />
    </div>
  )
}

export default React.memo(Navbar)
