import React from 'react'

import colors from '../../assets/colors.png'
import colorsWebp from '../../assets/colors.webp'
import crwn from '../../assets/crwn.png'
import crwnWebp from '../../assets/crwn.webp'
import devchat from '../../assets/devchat.png'
import dressify from '../../assets/dressify.png'
import dressifyWebp from '../../assets/dressify.webp'
import devchatWebp from '../../assets/devchat.webp'
import movies from '../../assets/movies.png'
import moviesWebp from '../../assets/movies.webp'
import Tooltip from '../../Components/Tooltip/Tooltip'
import Project from '../../Components/Project/Project'
import styles from './MyProjects.module.scss'

const MyProjects = () => {
  return (
    <div className={styles.container} id="myProjects">
      <div className={styles.myProjects}>
        <div className={styles.headerWrapper}>
          <h2 className={styles.header}>Selected Work</h2>
          <Tooltip />
        </div>
        <Project
          name="Dressify"
          image={dressify}
          imageWebp={dressifyWebp}
          color="rgb(157, 169, 238)"
          hoverColor="rgb(177, 189, 258)"
          link="dressify.andreikaras.com"
          githubLink="https://github.com/Justbigmack/Dressify"
          text="A full-stack web app for you to sell your items online. It is built with React Hooks, Apollo and Next.JS for the front end and Node.js, Express, Prisma and GraphQL for the backend. Tests are written with Jest and Enzyme."
        />
        <Project
          right
          name="CRWN Clothing"
          image={crwn}
          imageWebp={crwnWebp}
          color="rgb(240, 225, 89)"
          hoverColor="rgb(220, 205, 69)"
          link="crwnclothing.andreikaras.com"
          githubLink="https://github.com/Justbigmack/CRWN-Clothing"
          text="An e-commerce web-app that allows you to easily purchase clothes. Payments are processed via Stripe API. It is built with React, Redux, Redux-Sagas and Firebase"
        />
        <Project
          name="React Colors"
          image={colors}
          imageWebp={colorsWebp}
          project="colors"
          color="rgb(161, 106, 195)"
          hoverColor="rgb(181, 126, 215)"
          link="colors.andreikaras.com"
          githubLink="https://github.com/Justbigmack/react-colors"
          text="React Colors is an app allowing you to generate color palettes for your projects by leveraging easy to use UI and drag and drop system. The project is put together using React, CSS in JS, Material UI and features some React Router animations"
        />
        <Project
          right
          name="DevChat"
          image={devchat}
          imageWebp={devchatWebp}
          project="devchat"
          color="rgb(251, 93, 81)"
          hoverColor="rgb(271, 113, 101)"
          link="devchat.andreikaras.com"
          githubLink="https://github.com/Justbigmack/dev-chat"
          text="DevChat is a simplified clone of a well-known app - Slack. Devchat allows desktop and large tablet users to exchange messages, files and much more. The project is developed using React, Redux, Firebase and Semantic UI"
        />
        <Project
          name="React Movies"
          image={movies}
          imageWebp={moviesWebp}
          project="movies"
          color="rgb(134, 191, 211)"
          hoverColor="rgb(154, 211, 231)"
          link="movies.andreikaras.com"
          githubLink="https://github.com/Justbigmack/react-movies"
          text="React Movies is a web-application that allows you to search for your favorite movies. It utilizes React Router and pulls information from TMDB via API"
        />
      </div>
    </div>
  )
}

export default React.memo(MyProjects)
