import React from 'react'

import logo from '../../assets/Logo2x.png'
import styles from './Logo.module.scss'

const Logo = () => {
  return (
    <div>
      <img src={logo} alt="Logo" className={styles.logo} />
    </div>
  )
}

export default Logo
