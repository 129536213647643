import React from 'react'

import { ReactComponent as MailIcon } from '../../../assets/email.svg'
import styles from './EmailIcon.module.scss'

const EmailIcon = () => {
  return (
    <a href="mailto:andrew.karas@protonmail.com" rel="noopener noreferrer">
      <MailIcon className={styles.emailIcon} />
    </a>
  )
}

export default EmailIcon
