import React, { useState } from 'react'

import { ReactComponent as QuestionIcon } from '../../assets/tooltip.svg'
import styles from './Tooltip.module.scss'

const Tooltip = () => {
  const [visible, toggleVisibility] = useState(false)
  const [mounted, mount] = useState(false)
  return (
    <div className={styles.container}>
      <QuestionIcon
        className={styles.icon}
        onMouseEnter={() => {
          mount(true)
          toggleVisibility(true)
        }}
        onMouseLeave={() => {
          toggleVisibility(false)
          setTimeout(() => mount(false), 300)
        }}
      />
      {mounted && (
        <div
          className={
            visible ? styles.tooltip : [styles.tooltip, styles.hide].join(' ')
          }
        >
          <p>
            Dressify and CRWN-Clothing projects are hosted on a free Heroku
            plan. Once you visit them, allow for free dynos to start (it takes
            about 15-25 seconds). After that refresh the page and projects
            should be up and running.
          </p>
        </div>
      )}
    </div>
  )
}

export default Tooltip
