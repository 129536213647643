import React from 'react'

import styles from './AboutMe.module.scss'

const AboutMe = () => {
  return (
    <div className={styles.container} id="about-me">
      <div className={styles.about}>
        <div className={styles.what}>Who is that guy?</div>
        <div className={styles.wrapper}>
          <h2 className={styles.welcome}>
            Hey. I am Andrei, a Front-End Developer in Berlin, Germany.
          </h2>
          <h3 className={styles.intro}>
            I have serious passion for building excellent software. I specialize
            in Front-End development and excel at the following
          </h3>
          <div className={styles.skills}>
            <ol>
              <li>HTML5</li>
              <li>CSS3, SCSS</li>
              <li>JavaScript</li>
              <li>React</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(AboutMe)
