import React, { Component } from 'react'

import AboutMe from './Sections/AboutMe/AboutMe'
import Contact from './Sections/Contact/Contact'
import Hamburger from './UI/Hamburger/Hamburger'
import HeroSection from './Sections/Hero/Hero'
import MyProjects from './Sections/MyProjects/MyProjects'
import Navbar from './Sections/Navbar/Navbar'

import './App.scss'

class App extends Component {
  state = {
    showFront: true,
    frontClasses: ['page', 'show', 'hideOverflow'],
    bottomClasses: ['page', 'hide'],
    canTriggerTopAnimation: true,
    canTriggerBottomAnimation: false
  }

  hamburgerMenu = React.createRef()

  triggerAnimation = () => {
    const {
      showFront,
      canTriggerTopAnimation,
      canTriggerBottomAnimation
    } = this.state
    if (showFront && canTriggerTopAnimation) {
      this.hamburgerMenu.current.switchHamburgerColorState()
      this.setState(
        {
          showFront: !this.state.showFront,
          frontClasses: ['page', 'rotateCubeTopOut', 'show'],
          bottomClasses: ['page', 'rotateCubeTopIn', 'show', 'hideOverflow'],
          canTriggerTopAnimation: false
        },
        () => {
          setTimeout(() => {
            this.setState({
              frontClasses: ['page', 'hide'],
              bottomClasses: ['page', 'page2', 'show'],
              canTriggerBottomAnimation: true
            })
          }, 1500)
        }
      )
    } else if (!showFront && canTriggerBottomAnimation) {
      this.setState(
        {
          showFront: !this.state.showFront,
          frontClasses: ['page', 'rotateCubeBottomIn', 'show'],
          bottomClasses: [
            'page',
            'rotateCubeBottomOut',
            'show',
            'hideOverflow'
          ],
          canTriggerBottomAnimation: false
        },
        () => {
          setTimeout(() => {
            this.setState({
              frontClasses: ['page', 'show', 'hideOverflow'],
              bottomClasses: ['page', 'hide'],
              canTriggerTopAnimation: true
            })
            this.hamburgerMenu.current.switchHamburgerColorState()
          }, 1500)
        }
      )
    }
  }

  componentDidMount() {
    const mobile = () => 'ontouchstart' in window || navigator.msMaxTouchPoints

    if (mobile()) {
      let clientY
      const mobileTrackSections = [
        document.getElementById('Hero'),
        document.getElementById('secondPage')
      ]

      // setting height of the hero section on mobile
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)

      //listening to resizing on mobile so that screen size could be adjusted
      window.addEventListener('resize', () => {
        vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
      })

      // tracking touch event
      mobileTrackSections.forEach(element => {
        element.addEventListener(
          'touchstart',
          e => {
            clientY = e.touches[0].clientY
          },
          { capture: false, passive: true }
        )
      })

      // triggering animation on mobile
      mobileTrackSections.forEach(element => {
        element.addEventListener(
          'touchmove',
          e => {
            let deltaY
            deltaY = e.changedTouches[0].clientY - clientY

            // triggering animation on the first page
            if (this.state.canTriggerTopAnimation && deltaY <= -50) {
              this.triggerAnimation()
              this.hamburgerMenu.current.refreshSectionHeightValues()
            }

            //triggering animation on the second page
            if (
              this.state.canTriggerBottomAnimation &&
              deltaY >= 50 &&
              document.getElementById('secondPage').scrollTop <= 0
            ) {
              this.triggerAnimation()
            }
          },
          { capture: false, passive: true }
        )
      })
    }

    if (!mobile()) {
      //trigerring animation on the first page
      document.getElementById('Hero').addEventListener(
        'wheel',
        e => {
          if (this.state.canTriggerTopAnimation && e.deltaY >= 1) {
            this.triggerAnimation()
            this.hamburgerMenu.current.refreshSectionHeightValues()
          }
        },
        { capture: false, passive: true }
      )

      document.getElementById('secondPage').addEventListener(
        'wheel',
        e => {
          this.scrollDistanceFromTop = document.getElementById(
            'secondPage'
          ).scrollTop

          //triggering animation on the second page
          if (
            this.state.canTriggerBottomAnimation &&
            e.deltaY <= -1 &&
            this.scrollDistanceFromTop <= 0
          ) {
            this.triggerAnimation()
          }
        },
        { capture: false, passive: true }
      )
    }
  }

  render() {
    const { frontClasses, bottomClasses, canTriggerTopAnimation } = this.state
    return (
      <div className="App">
        <div className="appWrapper">
          <Hamburger
            ref={this.hamburgerMenu}
            canTriggerAnimation={canTriggerTopAnimation}
            triggerAnimation={this.triggerAnimation}
          />
          <div className="scene">
            <div className={frontClasses.join(' ')}>
              <Navbar />
              <HeroSection />
            </div>
            <div className={bottomClasses.join(' ')} id="secondPage">
              <AboutMe />
              <MyProjects />
              <Contact />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default App
