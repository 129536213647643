import React from 'react'

import cv from '../../../assets/cv.pdf'
import { ReactComponent as CVIcon } from '../../../assets/resume.svg'
import styles from './ResumeIcon.module.scss'

const ResumeIcon = () => {
  return (
    <a
      href={cv}
      download="Andrew Karas CV"
      className={styles.resumeIconContainer}
    >
      <CVIcon className={styles.resumeIcon} />
    </a>
  )
}

export default ResumeIcon
