import React from 'react'

import { ReactComponent as Github } from '../../../assets/github.svg'
import styles from './GhIcon.module.scss'

const GhIcon = () => {
  return (
    <a href="https://github.com/Justbigmack" className={styles.githubIconContainer}>
      <Github className={styles.githubIcon} />
    </a>
  )
}

export default GhIcon
