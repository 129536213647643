import React, { Component } from 'react'

import Menu from '../../Components/Menu/Menu'
import styles from './Hamburger.module.scss'

class Hamburger extends Component {
  state = {
    toggle: false,
    isBlack: false
  }

  scrollDistanceFromTop = 0
  aboutSectionHeight = 0
  projectsSectionHeight = 0

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !nextState.isBlack === this.state.isBlack ||
      !nextState.toggle === this.state.toggle
    )
  }

  switchToggleState = () => {
    this.setState({ toggle: !this.state.toggle })
  }

  switchHamburgerColorState = () => {
    this.setState({ isBlack: !this.state.isBlack })
  }

  refreshSectionHeightValues = () => {
    this.aboutSectionHeight = document.getElementById('about-me').offsetHeight
    this.projectsSectionHeight = document.getElementById(
      'myProjects'
    ).offsetHeight
  }

  componentDidMount() {
    const rem = parseFloat(getComputedStyle(document.documentElement).fontSize)
    const mobile = () => 'ontouchstart' in window || navigator.msMaxTouchPoints

    //handling color changes of hamburger menu
    const handleHamburgerColorChange = () => {
      if (
        this.scrollDistanceFromTop >= this.aboutSectionHeight - 3 * rem &&
        this.scrollDistanceFromTop <=
          this.aboutSectionHeight + this.projectsSectionHeight - 3 * rem &&
        this.state.isBlack
      )
        this.setState({ isBlack: false })
      else if (
        (this.scrollDistanceFromTop <= this.aboutSectionHeight - 3 * rem ||
          this.scrollDistanceFromTop >=
            this.aboutSectionHeight + this.projectsSectionHeight - 5 * rem) &&
        !this.state.isBlack
      )
        this.setState({ isBlack: true })
    }

    //attaching event listener to the second page of both mobile and desktop
    document.getElementById('secondPage').addEventListener('scroll', () => {
      this.scrollDistanceFromTop = document.getElementById(
        'secondPage'
      ).scrollTop
      this.refreshSectionHeightValues()
      handleHamburgerColorChange()
    })

    //track changes of section heights and hamburger color when resizing
    if (!mobile)
      window.addEventListener('resize', () => {
        setTimeout(() => {
          this.refreshSectionHeightValues()
          handleHamburgerColorChange()
        }, 250)
      })
  }

  render() {
    const { toggle, isBlack } = this.state
    const { canTriggerAnimation, triggerAnimation } = this.props
    return (
      <div>
        <div className={styles.hamburgerContainer}>
          <div
            className={styles.menuToggle}
            onClick={() => {
              this.setState({ toggle: !toggle })
            }}
          >
            <span
              className={[
                toggle ? styles.first : null,
                isBlack ? styles.black : null
              ].join(' ')}
            ></span>
            <span
              className={[
                toggle ? styles.second : null,
                isBlack ? styles.black : null
              ].join(' ')}
            ></span>
            <span
              className={[
                toggle ? styles.third : null,
                isBlack ? styles.black : null
              ].join(' ')}
            ></span>
          </div>
        </div>
        <Menu
          toggle={toggle}
          switchToggleState={this.switchToggleState}
          canTriggerAnimation={canTriggerAnimation}
          triggerAnimation={triggerAnimation}
        />
      </div>
    )
  }
}

export default Hamburger
