import React from 'react'

import arrow from '../../assets/Vector2x.png'
import styles from './Arrow.module.scss'

const Arrow = ({ hide }) => {
  return (
    <div className={hide && styles.arrowContainerHide}>
      <img src={arrow} alt="Scroll Down" className={styles.arrow} />
    </div>
  )
}

export default Arrow
